import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/molecules/seo';

const NotFoundPage = () => (
  <>
    <SEO
      title="No podemos encontrar la página que buscas | JR Web"
      description="Lo sentimos, la página que buscas no existe en esta web."
    />
    <section
      style={{
        margin: '4.375rem auto',
        maxWidth: '58.875rem',
      }}
    >
      <h1>No hemos podido encontrar la página que estás buscando.</h1>
      <p>¡Lo sentimos!</p>
      <p>¿Quizás la página que buscas es una de estas?</p>
      <ul>
        <li>
          <Link to="/">Inicio</Link>
        </li>
        <li>
          <Link to="/servicios">Servicios</Link>
        </li>
        <li>
          <Link to="/trabajos">Trabajos</Link>
        </li>
        <li>
          <Link to="/contacto">Contacto</Link>
        </li>
      </ul>
    </section>
  </>
);

export default NotFoundPage;
